import React, { useEffect, useState } from 'react';
import './style.scss';
import { Container, Tabs, Tab, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CustomButton from 'components/buttons/base';
import { COLORS } from 'constants/colors';
import { selectUser, logOut, selectMyOrders, userOrders } from 'store/slices/user/userSlice';

import { formatDate } from '../../utils/dateUtils';
import ReturnOrder from '../../components/checkout/returnOrder';

// eslint-disable-next-line complexity
export default function Profile() {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const user = useSelector(selectUser);
  const myOrders = useSelector(selectMyOrders);
  const history = useHistory();
  const dispatch = useDispatch();
  const [memberSince, setMemberSince] = useState(user?.createdAt);
  const [firstname, setFirstname] = useState(user?.firstname);
  const [lastname, setLastname] = useState(user?.lastname);
  const [email, setEmail] = useState(user?.email);
  const [streetAddress, setStreetAddress] = useState(user?.shippingDetails?.streetAddress);
  const [streetAddress2, setStreetAddress2] = useState(user?.shippingDetails?.streetAddress2);
  const [country] = useState('United States');
  const [state, setState] = useState(user?.shippingDetails?.state);
  const [zipCode, setZipCode] = useState(user?.shippingDetails?.zipCode);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [changing, setChanging] = useState(false);
  const [returnOrderMode, setReturnOrderMode] = useState(false);
  const [returnOrderId, setReturnOrderId] = useState(null);

  const states = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  useEffect(() => {
    setFirstname(user?.firstname);
    setLastname(user?.lastname);
    setEmail(user?.email);
    if (user?.created_at) {
      const formattedDate = formatDate(user?.created_at, '.');
      setMemberSince(formattedDate);
      // const dateMemberSince = new Date(user.created_at);
      // const month = dateMemberSince.getMonth() + 1 < 10 ? `0${dateMemberSince.getMonth() + 1}` : dateMemberSince.getMonth();
      // const day = dateMemberSince.getDate() < 10 ? `0${dateMemberSince.getDate()}` : dateMemberSince.getDate();
      // const year = dateMemberSince.getFullYear();
      // setMemberSince(`${month}/${day}/${year}`);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      dispatch(userOrders());
    }
  }, [user]);

  const checkPassword = () => {
    if (password || confirmPassword) {
      return password !== confirmPassword;
    }
    return false;
  };

  const selectTab = activeKey => {
    if (activeKey === 'logout') {
      dispatch(logOut());
      history.push('/');
    }
  };

  const handleReturnOrder = () => {
    setReturnOrderMode(false);
    setReturnOrderId(null);
  };

  const openReturnOrder = orderId => {
    setReturnOrderId(orderId);
    setReturnOrderMode(true);
  };

  // TODO: Make cancel function which will reset firstname, latsname... and then call setChanging(false)

  const cancelChanging = () => {
    setFirstname(user?.firstname);
    setLastname(user?.lastname);
    setEmail(user?.email);
    setPassword(null);
    setConfirmPassword(null);
    setChanging(false);
  };

  return (
    <Container className="profile">
      <Row className="profile__tabs-wrapper">
        <Tabs defaultActiveKey="profile" id="profile-tabs" className="md-3 profile__tabs" onSelect={selectTab}>
          <Tab eventKey="profile" title="Profile" className="profile__tab-profile">
            <div className="profile__tab-profile__header">
              <FontAwesomeIcon icon="user" />
              <p>{changing ? 'Edit Personal Data' : 'Personal Data'}</p>
            </div>
            {changing && (
              <>
                <p className="profile__tab-profile__explanation">Here you can edit user account details</p>
                <div className="profile__tab-profile__inputs">
                  <div className="profile__tab-profile__inputs__wrapper">
                    <div className="column">
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-firstname">First name</label>
                        <input
                          type="text"
                          placeholder=""
                          id="profile-inputs-firstname"
                          value={firstname}
                          onChange={e => setFirstname(e.target.value)}
                        />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-lastname">Last name</label>
                        <input
                          type="text"
                          placeholder=""
                          id="profile-inputs-lastname"
                          value={lastname}
                          onChange={e => setLastname(e.target.value)}
                        />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-email">Email</label>
                        <input
                          type="email"
                          placeholder=""
                          id="profile-inputs-email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-password">Password</label>
                        <input
                          type="password"
                          id="profile-inputs-password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          autoComplete="new-password"
                        />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-confirm-password">Confirm Password</label>
                        <input
                          type="password"
                          id="profile-inputs-confirm-password"
                          value={confirmPassword}
                          onChange={e => setConfirmPassword(e.target.value)}
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div className="column">
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-street-address">Shipping Address</label>
                        <input
                          type="text"
                          placeholder=""
                          id="profile-inputs-street-address"
                          value={streetAddress}
                          onChange={e => setStreetAddress(e.target.value)}
                        />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-street-address-2">Shipping Address 2</label>
                        <input
                          type="text"
                          placeholder=""
                          id="profile-inputs-street-address-2"
                          value={streetAddress2}
                          onChange={e => setStreetAddress2(e.target.value)}
                        />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-country">Country</label>
                        <input type="text" placeholder="" disabled id="profile-inputs-country" value={country} readOnly />
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-state">State</label>
                        <div className="select">
                          <select onChange={event => setState(event.target.value)} value={state}>
                            <option value="">---</option>
                            {/* TODO: Fix pre-selected */}
                            {states.map(USState => (
                              <option key={`profile-state-${USState}`} value={USState} selected={state === USState}>
                                {USState}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="profile-inputs-confirm-zip-code">Zip Code</label>
                        <input
                          type="text"
                          id="profile-inputs-confirm-zip-code"
                          value={zipCode}
                          onChange={e => setZipCode(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-wrapper button-wrapper">
                    <CustomButton
                      disabled={!firstname || !lastname || !email || !password || !confirmPassword || checkPassword()}
                      outline
                      fontSize="1.4rem"
                      size="full"
                      text="SAVE"
                      onClick={() => {}}
                      borderColor={COLORS.PINK}
                      color={COLORS.PINK}
                    />
                    <CustomButton
                      fontSize="1.4rem"
                      size="full"
                      text="CANCEL"
                      onClick={() => cancelChanging()}
                      border="none"
                      backgroundColor={COLORS.PINK}
                      color={COLORS.WHITE}
                    />
                  </div>
                </div>
              </>
            )}
            {!changing && (
              <div className="profile__tab-profile__show">
                <div className="profile__tab-profile__show__wrapper">
                  <div className="column">
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-date">Member since:</label>
                      <input type="text" placeholder="" id="profile-show-date" value={memberSince} readOnly />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-firstname">First name</label>
                      <input type="text" placeholder="" id="profile-show-firstname" value={firstname} readOnly />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-lastname">Last name</label>
                      <input type="text" placeholder="" id="profile-show-lastname" value={lastname} readOnly />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-email">Email</label>
                      <input type="email" placeholder="" id="profile-show-email" value={email} readOnly />
                    </div>
                  </div>
                  <div className="column">
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-street-address">Shipping address</label>
                      <input type="text" placeholder="" id="profile-show-street-address" value={streetAddress} readOnly />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-street-address-2">Shipping address 2</label>
                      <input type="text" placeholder="" id="profile-show-street-address-2" value={streetAddress2} readOnly />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-country">Country</label>
                      <input type="text" placeholder="" id="profile-show-country" value={country} readOnly />
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-state">State</label>
                      <input type="text" placeholder="" id="profile-show-state" value={state} readOnly />
                    </div>
                  </div>
                  <div className="column">
                    <div className="input-wrapper">
                      <label htmlFor="profile-show-zip-code">Zip Code</label>
                      <input type="text" placeholder="" id="profile-show-zip-code" value={zipCode} readOnly />
                    </div>
                  </div>
                </div>
                <div className="input-wrapper button-wrapper">
                  <CustomButton
                    outline
                    fontSize="1.4rem"
                    size="full"
                    text="CHANGE"
                    onClick={() => setChanging(true)}
                    borderColor={COLORS.PINK}
                    color={COLORS.PINK}
                  />
                  <CustomButton
                    fontSize="1.4rem"
                    size="full"
                    text="CONTINUE SHOPPING"
                    onClick={() => history.push('/')}
                    border="none"
                    backgroundColor={COLORS.PINK}
                    color={COLORS.WHITE}
                  />
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="payment" title="Payment">
            <p>Payment information</p>
          </Tab>
          <Tab eventKey="orders" title="Orders" className="profile__tab-orders">
            {returnOrderMode && <ReturnOrder createReturn={handleReturnOrder} orderId={returnOrderId} />}
            {!returnOrderId && (
              <>
                <div className="profile__tab-orders__header">
                  <p>My orders</p>
                </div>
                <div className="profile__tab-orders__orders">
                  {myOrders.map(order => (
                    <div key={order.orderId} className="profile__tab-orders__order">
                      <div className="order-inner-wrapper">
                        <div className="profile__tab-orders__order__status">
                          <p>{order.status}</p>
                        </div>
                        <div className="profile__tab-orders__order__info">
                          <div className="order-image">
                            <img src={`${BASE_URL}${order.orderedProducts[0]?.product?.mainImage?.url}`} alt="" />
                          </div>
                          <div className="order-info">
                            <p className="order-info__grayed">Ordered: {formatDate(order.created_at, '.')}</p>
                            <p className="order-info__grayed">Order number: {order.orderId}</p>
                            <p>{order.orderedProducts?.length || 0} item(s)</p>
                          </div>
                          <div className="order-total">
                            <p className="order-total__grayed">Total:</p>
                            <p>${order.total || 0.0}</p>
                          </div>
                        </div>
                        <div className="profile__tab-orders__order__footer">
                          <CustomButton
                            text="RETURN ORDER"
                            onClick={() => openReturnOrder(order.id)}
                            gradient
                            size="medium"
                            height="52px"
                            width="40%"
                            color="white"
                            fontSize="1.4rem"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Tab>
          <Tab eventKey="logout" title="Logout" />
        </Tabs>
      </Row>
    </Container>
  );
}
