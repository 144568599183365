import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import 'App.scss';
import { useDispatch, useSelector } from 'react-redux';

import history from 'store/history';
import Header from 'components/header';
import TopHeaderNotification from 'components/topHeaderNotification';
import Cart from 'components/cart';
import { selectIsAuthenticated, userAuthenticate } from 'store/slices/user/userSlice';
import Footer from 'components/footer';
import { getCategories, getAppSettings, selectOverlayStatus, toggleOverlay } from 'store/slices/app/appSlice';
import Login from 'pages/login';
import Product from 'pages/product';
import Home from 'pages/home';
import Category from 'pages/category';
import Tag from 'pages/tag';
import Profile from 'pages/profile';
import Checkout from 'pages/checkout';
import Appointment from 'pages/appointment';
import PrivateRoute from 'core/PrivateRoute';
import { toggleCart } from 'store/slices/cart/cartSlice';
import SideMenu from 'components/menus/sideMenu';
import { useMediaQuery } from 'utils/hooks';

function App() {
  const dispatch = useDispatch();
  const overlayStatus = useSelector(selectOverlayStatus);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [shouldShowLayoutElements, setShouldShowLayoutElements] = useState(history.location.pathname !== '/login');

  history.listen(location => {
    setShouldShowLayoutElements(location.pathname !== '/login');
  });

  useEffect(() => {
    dispatch(userAuthenticate());
    dispatch(getCategories());
    dispatch(getAppSettings());
  }, []);

  const toggleCartAndOverlay = () => {
    dispatch(toggleOverlay());
    dispatch(toggleCart());
  };

  // TODO: Implement Private Routes
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <>
          {shouldShowLayoutElements && <TopHeaderNotification />}
          {shouldShowLayoutElements && <Header />}
          {isMobile && <SideMenu />}
          <div className="page-content">
            <Switch>
              <PrivateRoute isAuthenticated={isAuthenticated} exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              {/* <Route exact path="/login-register" component={LoginRegister} /> */}
              <PrivateRoute isAuthenticated={isAuthenticated} exact path="/profile" component={Profile} />
              <PrivateRoute isAuthenticated={isAuthenticated} path="/product/:id" component={Product} />
              <PrivateRoute isAuthenticated={isAuthenticated} path="/category/:id" component={Category} />
              <PrivateRoute isAuthenticated={isAuthenticated} path="/tag/:id" component={Tag} />
              <PrivateRoute isAuthenticated={isAuthenticated} path="/checkout" component={Checkout} />
              <PrivateRoute isAuthenticated={isAuthenticated} path="/appointment" component={Appointment} />
              <Route render={() => <div>404</div>} />
            </Switch>
          </div>
          {overlayStatus && <div onClick={toggleCartAndOverlay} className="overlay" />}
          {shouldShowLayoutElements && <Cart />}
          {shouldShowLayoutElements && <Footer />}
        </>
      </ConnectedRouter>
    </div>
  );
}

export default App;
