import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { sendOrder } from './orderAPI';

const initialState = {
  cartOpen: false,
  items: [],
  coupon: null,
  subtotal: 0,
  total: 0,
  orderStatus: 'idle',
  lastConfirmedOrder: null,
  error: null
};
// const faker = async (timeout, rejectPromise = false) => new Promise((resolve, reject) => {
//     setTimeout(rejectPromise ? reject : resolve, timeout);
// });

export const postOrder = createAsyncThunk('cart/createOrder', async data => {
  // await faker(120000);
  const response = await sendOrder(data);

  return response.data;
});

const calculateTotal = items => {
  let total = 0;
  items.forEach(item => {
    total += item.quantity * parseFloat(item.price);
  });
  return total;
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    toggleCart: state => {
      state.cartOpen = !state.cartOpen;
    },
    clearCart: state => {
      state.cartOpen = false;
      state.items = [];
      state.coupon = null;
      state.subtotal = 0;
      state.total = 0;
      state.orderStatus = 'idle';
      state.lastConfirmedOrder = null;
      state.error = null;
    },
    addItemToCart: (state, action) => {
      const product = action.payload.product;
      const quantity = action.payload.quantity || 1;
      if (product) {
        const itemIndex = state.items.findIndex(item => item.id === product.id);
        if (itemIndex > -1) {
          state.items[itemIndex].quantity += quantity;
        } else {
          state.items.push({
            ...product,
            quantity
          });
        }
      }
      state.total = calculateTotal(state.items);
      state.subtotal = calculateTotal(state.items);
    },
    setItemQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const quantityNumber = parseInt(quantity, 10);
      const itemIndex = state.items.findIndex(item => item.id === id);
      if (itemIndex > -1 && quantityNumber >= 0) {
        state.items[itemIndex].quantity = quantityNumber;
      }
      state.total = calculateTotal(state.items);
      state.subtotal = calculateTotal(state.items);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(postOrder.pending, state => {
        state.orderStatus = 'loading';
      })
      .addCase(postOrder.fulfilled, (state, action) => {
        state.orderStatus = 'idle';
        state.lastConfirmedOrder = action.payload;
      })
      .addCase(postOrder.rejected, (state, action) => {
        state.orderStatus = 'error';

        state.error = action.payload;
        console.log(action.payload);
      });
  }
});

export const { toggleCart, addItemToCart, setItemQuantity, clearCart } = cartSlice.actions;

export const selectCartStatus = state => state.cart.cartOpen;
export const selectCartItems = state => state.cart.items;
export const selectCartSubtotla = state => state.cart.subtotal;
export const selectCartTotal = state => state.cart.total;
export const selectOrderError = state => state.cart.error;
export const selectOrderStatus = state => state.cart.orderStatus;
export const selectLastConfirmedOrder = state => state.cart.lastConfirmedOrder;

export default cartSlice.reducer;
