export const formatDate = (date, separator = '/') => {
  let dateObject = null;

  if (!date) return '';

  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }

  const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
  const day = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();
  const year = dateObject.getFullYear();

  return `${month}${separator}${day}${separator}${year}${separator === '.' ? separator : ''}`;
};
