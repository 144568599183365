import React, { useState } from 'react';
import './styles.scss';
import { useSelector } from 'react-redux';

import { selectShippingSettings } from 'store/slices/app/appSlice';

import { COLORS } from '../../../constants/colors';
import CustomButton from '../../buttons/base';

export default function Shipping({ setShippingDetails }) {
  const [shippingOption, setShippingOption] = useState(false);
  const shippingOptions = useSelector(selectShippingSettings)?.options ?? [];
  
  const setData = () => {
    const data = {
      shippingOption
    };

    setShippingDetails(data);
  };

  return (
    <>
      <div className="customer-shipping">
        {/* <div className="customer-shipping__delivery"> */}
        {/*  <p className="customer-shipping__delivery__title">Delivery Method</p> */}
        {/*  <div className="customer-shipping__delivery__options"> */}
        {/*    <div className="option-wrapper"> */}
        {/*      <div className="option-radio"> */}
        {/*        <input type="radio" id="delivery-1" /> */}
        {/*        <label htmlFor="delivery-1"/> */}
        {/*      </div> */}
        {/*      <div className="option-info"> */}
        {/*        <div className="option"> */}
        {/*          <div className="option-title">A2B Express Delivery</div> */}
        {/*          <div className="option-description">Lorem ipsum dolor sit amet...</div> */}
        {/*        </div> */}
        {/*        <div className="price"> */}
        {/*          <p>$0.00</p> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        {/* <div className="customer-shipping__payment"> */}
        {/*  <p className="customer-shipping__delivery__title">Method of payment</p> */}
        {/*  <div className="customer-shipping__delivery__options"> */}
        {/*    <div className="option-wrapper"> */}
        {/*      <div className="option-radio"> */}
        {/*        <input type="radio" id="payment-1" /> */}
        {/*        <label htmlFor="payment-1"/> */}
        {/*      </div> */}
        {/*      <div className="option-info"> */}
        {/*        <div className="option"> */}
        {/*          <div className="option-title">Electronic payment</div> */}
        {/*          <div className="option-description">Card payment</div> */}
        {/*        </div> */}
        {/*        <div className="price"> */}
        {/*          <p>$0.00</p> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        <div className="customer-shipping__shipping-method">
          <p className="customer-shipping__delivery__title">Shipping Method</p>
          <div className="customer-shipping__delivery__options">
            {shippingOptions.map( option => (
              <div className="option-wrapper" key={`shipping-key-${option.id}`}>
                <div className="option-radio">
                  <input
                    type="radio"
                    id={`shipping-${option.id}`}
                    value={option.id}
                    onChange={event => setShippingOption(event.target.value)}
                  />
                  <label htmlFor={`shipping-${option.id}`} />
                </div>
                <div className="option-info">
                  <div className="option">
                    <div className="option-title">{option.name}</div>
                    <div className="option-description">{option.shortDescription}</div>
                  </div>
                  <div className="price">
                    <p>${option.price?.toFixed(2) || '0.00'}</p>
                  </div>
                </div>
            </div>
            ))}
          </div>
        </div>
      </div>
      <CustomButton
        outline
        fontSize="1.4rem"
        size="full"
        text="GO ON"
        onClick={setData}
        borderColor={COLORS.PINK}
        color={COLORS.PINK}
      />
    </>
  );
}
