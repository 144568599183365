import { applyMiddleware, compose, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import counterReducer from 'store/slices/counter/counterSlice';
import userReducer from 'store/slices/user/userSlice';
import cartReducer from 'store/slices/cart/cartSlice';
import appReducer from 'store/slices/app/appSlice';

import history from './history';

export const store = configureStore({
  reducer: {
    router: connectRouter(history),
    counter: counterReducer,
    user: userReducer,
    cart: cartReducer,
    app: appReducer
  },
  middlewares: [compose(applyMiddleware(routerMiddleware(history), thunk))]
});
