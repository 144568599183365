import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router';

import './styles.scss';
import CustomButton from 'components/buttons/base';
import ProductsCarousel from 'components/productsCarousel';
import CategoriesCarousel from 'components/categoriesCarousel';
import { selectHomepageSettings } from 'store/slices/app/appSlice';
import { getProductsFromCategories, getProductsFromTag } from 'store/slices/app/productAPI';

import {userWishlist} from "../../store/slices/user/userSlice";

// eslint-disable-next-line complexity
export default function Home() {
  const homepageSettings = useSelector(selectHomepageSettings);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const getCarouselProducts = async () => {
    if (homepageSettings?.productsSlider?.category) {
      const categoryProducts = await getProductsFromCategories(homepageSettings.productsSlider.category.id, 1, 10);
      setProducts(categoryProducts?.data || []);
    } else if (homepageSettings?.productsSlider?.tag) {
      const categoryProducts = await getProductsFromTag(homepageSettings.productsSlider.tag.id, 1, 10);
      setProducts(categoryProducts?.data || []);
    } else if (homepageSettings?.productsSlider?.products) {
      setProducts(homepageSettings.productsSlider.products || []);
    }
  };

  const getBannerCTAPath = banner => {
    if (banner?.category) {
      history.push(`/category/${banner.category.id}`);
    } else if (banner?.tag) {
      history.push(`/tag/${banner.tag.id}`);
    }

    if (banner?.buttonCustomLink?.startsWith('/')) {
      history.push(banner?.buttonCustomLink);
    } else {
      window.location.href = banner?.buttonCustomLink;
    }
  };

  useEffect(() => {
    getCarouselProducts();
  }, [homepageSettings]);

  useEffect(() => {
    dispatch(userWishlist());
  }, []);

  return (
    <Container className="home">
      {homepageSettings?.fullWidthPromoBanner && (
        <Row
          className="home__promo-full"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URL}${homepageSettings?.fullWidthPromoBanner?.backgroundImage?.url})`
          }}
        >
          <div className="home__promo-full__title">
            {homepageSettings?.fullWidthPromoBanner?.title && <h3>{homepageSettings.fullWidthPromoBanner.title}</h3>}
          </div>
          <div className="home__promo-full__description">
            {homepageSettings?.fullWidthPromoBanner?.subtitle && <h5>{homepageSettings.fullWidthPromoBanner.subtitle}</h5>}
          </div>
          {homepageSettings?.fullWidthPromoBanner?.buttonText && (
            <div className="home__promo-full__button">
              <CustomButton
                gradient
                size="medium"
                text={homepageSettings.fullWidthPromoBanner.buttonText}
                icon="long-arrow-right"
                height="52px"
                color="white"
                fontSize="1.4rem"
                onClick={() => getBannerCTAPath(homepageSettings.fullWidthPromoBanner)}
              />
            </div>
          )}
        </Row>
      )}
      <Row className="home__promo-half">
        <Col xs={12} sm={6} className="home__promo-half__left">
          {homepageSettings?.leftHalfWidthBanner?.backgroundImage?.url && (
            <div className="home__promo-half__left-image">
              <img
                src={`${process.env.REACT_APP_API_URL}${homepageSettings?.leftHalfWidthBanner?.backgroundImage?.url}`}
                alt="promo"
              />
            </div>
          )}
          <div className="home__promo-half__left-description">
            {homepageSettings?.leftHalfWidthBanner?.title && <p>{homepageSettings.leftHalfWidthBanner.title}</p>}
            {homepageSettings?.leftHalfWidthBanner?.buttonText && (
              <CustomButton
                gradient
                size="medium"
                text={homepageSettings.leftHalfWidthBanner.buttonText}
                icon="long-arrow-right"
                height="52px"
                color="white"
                fontSize="1.4rem"
                onClick={() => getBannerCTAPath(homepageSettings.leftHalfWidthBanner)}
              />
            )}
          </div>
        </Col>
        <Col xs={12} sm={6} className="home__promo-half__right">
          {homepageSettings?.rightHalfWidthBanner?.backgroundImage?.url && (
            <div className="home__promo-half__right-image">
              <img
                src={`${process.env.REACT_APP_API_URL}${homepageSettings.rightHalfWidthBanner.backgroundImage.url}`}
                alt="promo"
              />
            </div>
          )}
          <div className="home__promo-half__right-description">
            {homepageSettings?.rightHalfWidthBanner?.title && <p>{homepageSettings.rightHalfWidthBanner.title}</p>}
            {homepageSettings?.rightHalfWidthBanner?.buttonText && (
              <CustomButton
                gradient
                size="medium"
                text={homepageSettings.rightHalfWidthBanner.buttonText}
                icon="long-arrow-right"
                height="52px"
                color="white"
                fontSize="1.4rem"
                onClick={() => getBannerCTAPath(homepageSettings.rightHalfWidthBanner)}
              />
            )}
          </div>
        </Col>
      </Row>
      {products?.length > 0 && (
        <>
          <Row className="home__hr">
            <Col className="home__hr-line" />
          </Row>
          <Row className="home__new">
            <ProductsCarousel products={products} title="What’s NEW" />
          </Row>
        </>
      )}
      {homepageSettings?.categoriesSlider?.categories.length > 0 && (
        <>
          <Row className="home__hr">
            <Col className="home__hr-line" />
          </Row>
          <Row className="home__categories">
            <CategoriesCarousel
              categories={homepageSettings.categoriesSlider.categories || []}
              title={homepageSettings.categoriesSlider.title}
            />
          </Row>
        </>
      )}
    </Container>
  );
}
