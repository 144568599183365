import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import Slider from 'react-slick';
import { useHistory } from 'react-router';

import PreviousPageArrowImage from 'assets/images/black-long-left-arrow.svg';
import PreviousPageArrowImageDisabled from 'assets/images/disabled-long-left-arrow.svg';
import NextPageArrowImage from 'assets/images/black-long-right-arrow.svg';
import NextPageArrowImageDisabled from 'assets/images/disabled-long-right-arrow.svg';
import { useMediaQuery } from 'utils/hooks';
import LeftArrow from 'assets/images/left-arrow.png';
import RightArrow from 'assets/images/right-arrow.png';

export default function CategoriesCarousel({ categories, title }) {
  const slider = useRef();
  const history = useHistory();
  const [perPage, setPerPage] = useState(5);
  const [nextAmount, setNextAmount] = useState(categories.length - perPage);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(categories.length <= perPage);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setNextAmount(categories.length - perPage);
    setNextDisabled(categories.length <= perPage);
  }, [categories]);

  useEffect(() => {
    setPerPage(isMobile ? 1 : 4);
    setNextAmount(categories.length - (isMobile ? 1 : 4));
    setNextDisabled(categories.length <= (isMobile ? 1 : 4));
  }, [isMobile]);

  const next = () => {
    if (currentSlide < nextAmount) {
      slider.current.slickNext();
    }
  };
  const previous = () => {
    slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: perPage,
    slidesToScroll: 1,
    beforeChange: (current, nextSlideIndex) => {
      setNextDisabled(nextSlideIndex >= nextAmount);
      setPrevDisabled(nextSlideIndex === 0);
      setCurrentSlide(nextSlideIndex);
    }
  };

  return (
    <div className="carousel">
      {title && (
        <div className="carousel__header">
          <h3>{title}</h3>
          {!isMobile && (
            <div className="carousel__header__controls">
              <img
                src={prevDisabled ? PreviousPageArrowImageDisabled : PreviousPageArrowImage}
                alt="previous"
                onClick={previous}
              />
              <img src={nextDisabled ? NextPageArrowImageDisabled : NextPageArrowImage} alt="next" onClick={next} />
            </div>
          )}
        </div>
      )}
      <div className="carousel__slider">
        <div className="carousel__slider__prev">
          <img src={LeftArrow} alt="" onClick={previous} />
        </div>
        <div className="carousel__slider__slide">
          <Slider className="carousel__categories" {...settings} ref={slider}>
            {categories.map(category => (
              <div key={category.id} onClick={() => history.push(`/category/${category.id}`)}>
                <div className="carousel__categories-category" id={category.id}>
                  <div className="carousel__categories-category__image">
                    <img
                      className="carousel__categories-category__image-main"
                      src={`${process.env.REACT_APP_API_URL}${category.image.url}`}
                      alt={category.name}
                    />
                  </div>
                  <div className="carousel__categories-category__info">
                    <p className="carousel__categories-category__info-name">{category.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="carousel__slider__next">
          <img src={RightArrow} alt="" onClick={next} />
        </div>
      </div>
    </div>
  );
}
