import React, { useState } from 'react';

import './styles.scss';
import CustomButton from '../../buttons/base';
import { COLORS } from '../../../constants/colors';

// eslint-disable-next-line no-unused-vars
export default function ReturnOrder({ orderId, createReturn }) {
  const [reason, setReason] = useState(null);
  const [message, setMessage] = useState('');
  const reasons = ['Reason 1', 'Reason 2', 'Reason 3', 'Reason 4', 'Reason 5'];

  return (
    <div className="return-order">
      <div className="return-order__header">
        <p onClick={createReturn}>Back</p>
        <p>Why returning items?</p>
      </div>
      <div className="return-order__inputs">
        <div className="return-order__inputs__input">
          <div className="select">
            <select onChange={event => setReason(event.target.value)} value={reason} placeholder="Select reason">
              {reasons.map((returnReason, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <option key={`reason-${index}`} value={returnReason}>
                  {returnReason}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="return-order__inputs__input">
          <textarea value={message} onChange={event => setMessage(event.target.value)} rows={10} placeholder="Tell us more" />
        </div>
      </div>
      <div className="return-order__actions">
        <CustomButton
          outline
          fontSize="1.4rem"
          size="full"
          text="PRINT RETURN LABEL"
          onClick={() => {}}
          borderColor={COLORS.PINK}
          color={COLORS.PINK}
        />
        <CustomButton
          fontSize="1.4rem"
          size="full"
          text="FINISH"
          onClick={createReturn}
          border="none"
          backgroundColor={COLORS.PINK}
          color={COLORS.WHITE}
        />
      </div>
    </div>
  );
}
