import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function getGeneralSettings() {
  return axios.get(`${BASE_URL}/general-settings`);
}

export async function getHomePageSettings() {
  return axios.get(`${BASE_URL}/homepage-settings`);
}

export async function getMenuSettings() {
  return axios.get(`${BASE_URL}/menu-settings`);
}

export async function getNotificationsSettings() {
  return axios.get(`${BASE_URL}/notifications-settings`);
}

export async function getShippingSettings() {
  return axios.get(`${BASE_URL}/shipping-settings`);
}

export async function getSettings() {
  return axios.get(`${BASE_URL}/settings`);
}
