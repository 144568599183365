import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Product from 'components/product';
import { COLORS } from 'constants/colors';
import CustomButton from 'components/buttons/base';
import { getProductsFromTag } from 'store/slices/app/productAPI';
import { getTag } from 'store/slices/app/tagAPI';

export default function Tag() {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [tag, setTag] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage] = useState(4);
  const productsPerPage = 50;

  const fetchData = async (flush = false) => {
    let productsData = [];
    let tagProducts = [];
    const tagResponse = await getTag(id);
    const tagData = tagResponse?.data;
    const tagProductsResponse = await getProductsFromTag(id, page, productsPerPage);
    tagProducts = tagProductsResponse?.data || [];
    if (flush) {
      productsData = tagProducts;
    } else {
      productsData = [...products, ...tagProducts];
    }
    setProducts(productsData || []);
    setTag(tagData);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    setProducts([]);
    setPage(1);
    setTag(null);
    fetchData(true);
  }, [id]);

  return (
    <>
      <Container className="category">
        {tag && (
          <Row className="category__title">
            <p>
              {tag.name} / {tag.productsCount} {tag.productsCount > 1 ? 'Items' : 'Item'}
            </p>
          </Row>
        )}
        <Row className="category__products">
          {products.map(product => (
            <Col xs={6} sm={6} md={12 / perPage} key={`product-${product.id}`} className="category__products__product">
              <Product product={product} />
            </Col>
          ))}
        </Row>
        <Row className="category__load-more">
          <CustomButton
            disabled={!tag?.productsCount || products?.length >= tag?.productsCount}
            outline
            fontSize="1.4rem"
            size="small"
            text="LOAD MORE"
            onClick={() => setPage(page + 1)}
            borderColor={COLORS.PINK}
            height="5rem"
            color={COLORS.PINK}
          />
        </Row>
      </Container>
    </>
  );
}
