import React, { useState } from 'react';

import './styles.scss';
import ApplePayLogo from 'assets/images/apple-pay.png';
import PaymentProviders from 'assets/images/card-payment-providers.png';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from 'utils/cardUtils';
import { COLORS } from 'constants/colors';
import CustomButton from 'components/buttons/base';

export default function Payment({ setPaymentDetails }) {
  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExp, setCardExp] = useState('');
  const [cardCVC, setCardCVC] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [country] = useState('United States');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const states = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  const setData = () => {
    const data = {
      email,
      cardNumber,
      cardExp,
      cardCVC,
      nameOnCard,
      country,
      state,
      zip
    };

    setPaymentDetails(data);
  };

  return (
    <>
      <div className="payment">
        <div className="payment__apple-pay">
          <img src={ApplePayLogo} alt="apple-pay" />
        </div>
        <div className="payment__hr-message">
          <div className="left-line" />
          <p className="message-content">Or pay with card</p>
          <div className="right-line" />
        </div>
        <div className="payment__inputs">
          <div className="payment__inputs__input">
            <label htmlFor="checkout-payment-email">Email</label>
            <input type="email" id="checkout-payment-email" value={email} onChange={event => setEmail(event.target.value)} />
          </div>
          <div className="payment__inputs__credit-card-group">
            <p className="payment__inputs__credit-card-group__label">Card information</p>
            <div className="payment__inputs__input card-number">
              <input
                type="text"
                placeholder="1234 1234 1234 1234"
                value={cardNumber}
                id="checkout-payment-credit-card-number"
                onChange={event => setCardNumber(formatCreditCardNumber(event.target.value))}
              />
            </div>
            <div className="payment__inputs__input card-exp">
              <input
                type="text"
                placeholder="MM / YY"
                value={cardExp}
                id="checkout-payment-credit-card-exp"
                onChange={event => setCardExp(formatExpirationDate(event.target.value))}
              />
            </div>
            <div className="payment__inputs__input card-cvc">
              <input
                type="text"
                placeholder="CVC"
                value={cardCVC}
                id="checkout-payment-credit-card-cvc"
                onChange={event => setCardCVC(formatCVC(event.target.value))}
              />
              <img className="payment__inputs__input__payment-providers" src={PaymentProviders} alt="payment-providers" />
            </div>
          </div>
          <div className="payment__inputs__input">
            <label htmlFor="checkout-payment-card-name">Name on card</label>
            <input
              type="text"
              id="checkout-payment-card-name"
              value={nameOnCard}
              onChange={event => setNameOnCard(event.target.value)}
            />
          </div>
          <div className="payment__inputs__input">
            <label htmlFor="checkout-payment-country">Country or region</label>
            <div className="select select-disabled">
              <select disabled>
                <option selected value="United States">
                  United states
                </option>
              </select>
            </div>
          </div>
          <div className="payment__inputs__input">
            <label htmlFor="checkout-payment-state">State</label>
            <div className="select">
              <select onChange={event => setState(event.target.value)} value={state}>
                <option value="">---</option>
                {states.map(USState => (
                  <option key={`state-${USState}`} value={USState}>
                    {USState}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="payment__inputs__input">
            <label htmlFor="checkout-payment-zip">ZIP</label>
            <input type="text" id="checkout-payment-zip" value={zip} onChange={event => setZip(event.target.value)} />
          </div>
        </div>
      </div>
      <CustomButton
        outline
        fontSize="1.4rem"
        size="full"
        text="PAY"
        onClick={setData}
        borderColor={COLORS.PINK}
        color={COLORS.PINK}
      />
    </>
  );
}
