import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAllCategories, getCategory } from './categoryAPI';
import { LOADING_STATUS } from '../../../constants/loadingStatuses';
import { getSettings } from './settingsAPI';
import { getTag } from './tagAPI';

const initialState = {
  overlay: false,
  sideMenu: false,
  categories: [],
  settings: {
    generalSettings: {},
    homepageSettings: {},
    menuSettings: {},
    notificationsSettings: {}
  },
  status: 'idle'
};

export const getCategories = createAsyncThunk('app/getCategories', async () => {
  const response = await getAllCategories();

  return response.data;
});

export const getAppSettings = createAsyncThunk('app/getSettings', async () => {
  const settings = await getSettings();

  const response = settings.data;

  if (response.homepageSettings && response.homepageSettings.productsSlider) {
    response.homepageSettings.productsSlider = {
      ...response.homepageSettings.productsSlider,
      tag: response.homepageSettings.productsSlider.tag
        ? (await getTag(response.homepageSettings.productsSlider.tag.id)).data
        : null,
      category: response.homepageSettings.productsSlider.category
        ? (await getCategory(response.homepageSettings.productsSlider.category.id)).data
        : null
    };
  }

  return response;
});

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleOverlay: state => {
      state.overlay = !state.overlay;
    },
    toggleSideMenu: state => {
      state.sideMenu = !state.sideMenu;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getCategories.pending, state => {
        state.status = LOADING_STATUS.LOADING;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.status = LOADING_STATUS.IDLE;
        state.categories = action.payload;
      })
      .addCase(getAppSettings.pending, state => {
        state.status = LOADING_STATUS.LOADING;
      })
      .addCase(getAppSettings.fulfilled, (state, action) => {
        state.settings = action.payload;
        state.status = LOADING_STATUS.IDLE;
      });
  }
});

export const { toggleOverlay, toggleSideMenu } = appSlice.actions;

export const selectOverlayStatus = state => state.app.overlay;
export const selectSideMenuStatus = state => state.app.sideMenu;
export const selectCategories = state => state.app.categories;
export const selectGeneralSettings = state => state.app.settings.generalSettings;
export const selectHomepageSettings = state => state.app.settings.homepageSettings;
export const selectMenuSettings = state => state.app.settings.menuSettings;
export const selectNotificationsSettings = state => state.app.settings.notificationsSettings;
export const selectShippingSettings = state => state.app.settings.shippingSettings;
export const selectSettings = state => state.app.settings;

export default appSlice.reducer;
