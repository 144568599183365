import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function sendOrder(data) {
  return axios.post(`${BASE_URL}/orders`, data);
}

export async function getUserOrders() {
  return axios.get(`${BASE_URL}/orders`);
}
