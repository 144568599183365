import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function getProduct(id) {
  return axios.get(`${BASE_URL}/products/${id}`);
}

export async function getTagWithProducts(id) {
  return axios.get(`${BASE_URL}/product-tags/${id}`);
}

export async function getProductsFromCategories(ids, page, perPage) {
  const query = ids.join('&categories_in=');
  return axios.get(`${BASE_URL}/products?categories_in=${query}&_limit=${perPage}&_start=${(page - 1) * perPage}`);
}

export async function getProductsFromTag(id, page, perPage) {
  return axios.get(`${BASE_URL}/products?product_tag=${id}&_limit=${perPage}&_start=${(page - 1) * perPage}`);
}
