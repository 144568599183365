import React from 'react';
import './styles.scss';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CloseImage from 'assets/images/close.svg';
import {
  selectCartStatus,
  toggleCart,
  selectCartItems,
  setItemQuantity,
  selectCartSubtotla,
  selectCartTotal
} from 'store/slices/cart/cartSlice';
import { toggleOverlay } from 'store/slices/app/appSlice';
import CustomButton from 'components/buttons/base';

export default function Cart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const cartStatus = useSelector(selectCartStatus);
  const cartItems = useSelector(selectCartItems);
  const cartSubtotal = useSelector(selectCartSubtotla);
  const cartTotal = useSelector(selectCartTotal);
  const toggleCartAndOverlay = () => {
    dispatch(toggleOverlay());
    dispatch(toggleCart());
  };
  const changeQuantity = (event, id) => {
    dispatch(setItemQuantity({ id, quantity: event.target.value }));
  };

  return (
    <div className={`cart ${cartStatus ? '' : 'hidden'}`}>
      <Row className="cart__header">
        <Col className="cart__header-title">Your Cart</Col>
        <Col className="cart__header-close">
          <img src={CloseImage} alt="close" onClick={toggleCartAndOverlay} />{' '}
        </Col>
      </Row>
      <hr className="cart__hr-title" />
      <div className="cart-wrapper">
        {cartItems.map(product => (
          <Row className="cart-wrapper__product-row" id={`cart-product-${product.id}`} key={`cart-product-${product.id}`}>
            <Col className="cart-wrapper__product-row__product-image" xs={3}>
              <img src={`${process.env.REACT_APP_API_URL}${product.mainImage?.url}`} alt="ring" />
            </Col>
            <Col className="cart-wrapper__product-row__product-info" xs={9}>
              <div className="cart-wrapper__product-row__product-info__name-price">
                <p>{product.name}</p>
                <p>${product.price.toFixed(2)}</p>
              </div>
              <input type="number" min="0" value={product.quantity} onChange={e => changeQuantity(e, product.id)} />
            </Col>
            {/* <Col className="cart-wrapper__product-row__product-price" xs={3}> */}
            {/*  <p>${product.price.toFixed(2)}</p> */}
            {/* </Col> */}
          </Row>
        ))}
        {(!cartItems || cartItems.length === 0) && <p className="cart-wrapper__empty-cart">Cart is empty</p>}
      </div>
      <hr className="cart__hr-subtotal" />
      <Row className="cart__subtotal">
        <Col className="cart__subtotal-text" xs={6}>
          Subtotal
        </Col>
        <Col className="cart__subtotal-amount" xs={6}>
          ${cartSubtotal.toFixed(2)}
        </Col>
      </Row>
      <hr className="cart__hr-total" />
      <Row className="cart__total">
        <Col className="cart__total-text" xs={6}>
          Total
        </Col>
        <Col className="cart__total-amount" xs={6}>
          ${cartTotal.toFixed(2)}
        </Col>
      </Row>
      <Row className="cart__coupon">
        <Col className="cart__coupon-input">
          <input type="text" placeholder="Enter promo code" />
        </Col>
      </Row>
      <Row className="cart__checkout">
        <Col className="cart__checkout-button">
          <CustomButton
            onClick={() => {
              history.push('/checkout');
              toggleCartAndOverlay();
            }}
            gradient
            size="medium"
            text="CHECK OUT"
            icon="long-arrow-right"
            height="52px"
            color="white"
            fontSize="1.4rem"
          />
        </Col>
      </Row>
    </div>
  );
}
