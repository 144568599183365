import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import LogoVertical from 'assets/images/logo-vertical.png';
import OutlinedButton from 'components/buttons/base';
import { COLORS } from 'constants/colors';
import { userLogin, selectIsAuthenticated, userRegister, selectError, clearError } from 'store/slices/user/userSlice';

// eslint-disable-next-line complexity
export default function Login() {
  const LOGIN_MODE = 'login';
  const REGISTER_MODE = 'register';

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('*First name is required'),
    lastName: Yup.string().required('*Last name is required'),
    phoneNumber: Yup.string().required('*Phone number is required'),
    storeName: Yup.string().required('*Store name is required'),
    city: Yup.string().required('*City is required'),
    state: Yup.string().required('*State is required'),
    website: Yup.string().required('*Website is required'),
    // dob: Yup.string()
    //   .required('Date of Birth is required')
    //   .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
    email: Yup.string().required('*Email is required').email('*Email is invalid'),
    password: Yup.string().min(6, '*Password must be at least 6 characters').required('*Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], '*Passwords must match')
      .required('*Confirm Password is required')
    // acceptTerms: Yup.bool()
    //   .oneOf([true], 'Accept Ts & Cs is required')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState(LOGIN_MODE);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const loginError = useSelector(selectError);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    reset();
    dispatch(clearError());
  }, [mode]);

  /**
   * componentWillMount
   */
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    if (isAuthenticated) {
      history.push('/');
    }
  });

  /**
   * componentWillUnmount
   */
  useEffect(
    () => () => {
      document.getElementsByTagName('body')[0].style.overflow = '';
    },
    []
  );

  const triggerLogin = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(userLogin({ email, password }));
  };

  // eslint-disable-next-line complexity
  const triggerRegister = data => {
    dispatch(
      userRegister({
        username: `sl${Math.floor(Math.random() * (1_000_000_000 - 1)) + 1}`,
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName,
        phoneNumber: data.phoneNumber,
        password: data.password
      })
    );
  };

  return (
    <div className="login hide" style={{ display: isAuthenticated ? 'none' : 'flex' }}>
      <div className="login__body">
        <div className="login__body-slider">
          <div className="login__body-slider__slider">
            <div className="login__body-slider__slider-slide">
              <div className="login__body-slider__slider-slide__banner">
                <div className="login__body-slider__slider-slide__banner-header">
                  <h1>STRENGTH. POWER. BEAUTY</h1>
                </div>
                <div className="login__body-slider__slider-slide__banner-body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui oﬃcia deserunt mollit anim id est
                  laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                </div>
                <div className="login__body-slider__slider-slide__banner-controls">
                  <div className="login__body-slider__slider-slide__banner-controls-previous">
                    <FontAwesomeIcon icon="long-arrow-alt-left" />
                    Previous image
                  </div>
                  <div className="login__body-slider__slider-slide__banner-controls-next">
                    Next image
                    <FontAwesomeIcon icon="long-arrow-alt-right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login__body-form">
          {mode === LOGIN_MODE && (
            <div className="login__body-form__wrapper">
              <img src={LogoVertical} alt="suzy levian logo" />
              <h4 className="login__body-form__wrapper-title-bold">Sign In</h4>
              <h4 className="login__body-form__wrapper-title">Please sign in to your Partner Account</h4>
              <p className="login__body-form__wrapper-error">{loginError.replace('Identifier', 'Email')}</p>
              <form className="login__body-form__wrapper-inputs">
                <input id="email" type="email" placeholder="Email" onChange={event => setEmail(event.target.value)} />
                <input id="password" type="password" placeholder="Password" onChange={event => setPassword(event.target.value)} />
                <OutlinedButton
                  text="Sign in"
                  borderColor={COLORS.PINK}
                  size="full"
                  fontSize="1.4rem"
                  color={COLORS.PINK}
                  outline
                  onClick={triggerLogin}
                />
              </form>
              <h4 className="login__body-form__wrapper-title-bold">Create an account!</h4>
              <h4 className="login__body-form__wrapper-title">Apply for a new Partner Account</h4>
              <OutlinedButton
                text="REGISTER"
                borderColor={COLORS.PINK}
                size="full"
                fontSize="1.4rem"
                color={COLORS.WHITE}
                gradient
                onClick={() => setMode(REGISTER_MODE)}
              />
            </div>
          )}
          {mode === REGISTER_MODE && (
            <div className="login__body-form__wrapper">
              <img src={LogoVertical} alt="suzy levian logo" />
              <h4 className="login__body-form__wrapper-title-bold">Create an account!</h4>
              <h4 className="login__body-form__wrapper-title">Apply for a new Partner Account</h4>
              <p className="login__body-form__wrapper-error">{loginError.replace('Identifier', 'Email')}</p>
              <form className="login__body-form__wrapper-inputs" onSubmit={handleSubmit(triggerRegister)}>
                <div className="login__register__form-register-inputs">
                  <div className={`login__register__form-register-inputs_name ${errors.firstName?.message ? 'has-error' : ''}`}>
                    <input type="text" placeholder="First name" {...register('firstName')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.firstName?.message}</div>
                  </div>
                  <div className={`login__register__form-register-inputs_last ${errors.lastName?.message ? 'has-error' : ''}`}>
                    <input type="text" placeholder="Last name" {...register('lastName')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.lastName?.message}</div>
                  </div>
                  <div className={`login__register__form-register-inputs_email ${errors.email?.message ? 'has-error' : ''}`}>
                    <input type="email" placeholder="Email" {...register('email')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.email?.message}</div>
                  </div>
                  <div
                    className={`login__register__form-register-inputs_phone ${errors.phoneNumber?.message ? 'has-error' : ''}`}
                  >
                    <input
                      type="text"
                      placeholder="Phone number"
                      {...register('phoneNumber')}
                      formNoValidate
                      autoComplete="off"
                    />
                    <div className="login__register__form-register-inputs_error">{errors.phoneNumber?.message}</div>
                  </div>
                  <div className={`login__register__form-register-inputs_store ${errors.storeName?.message ? 'has-error' : ''}`}>
                    <input type="text" placeholder="Store name" {...register('storeName')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.storeName?.message}</div>
                  </div>
                  <div className={`login__register__form-register-inputs_website ${errors.website?.message ? 'has-error' : ''}`}>
                    <input type="text" placeholder="Website" {...register('website')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.website?.message}</div>
                  </div>
                  <div className={`login__register__form-register-inputs_city ${errors.city?.message ? 'has-error' : ''}`}>
                    <input type="text" placeholder="City" {...register('city')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.city?.message}</div>
                  </div>
                  <div className={`login__register__form-register-inputs_state ${errors.state?.message ? 'has-error' : ''}`}>
                    <input type="text" placeholder="State" {...register('state')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.state?.message}</div>
                  </div>
                  <div
                    className={`login__register__form-register-inputs_password ${errors.password?.message ? 'has-error' : ''}`}
                  >
                    <input type="password" placeholder="Password" {...register('password')} formNoValidate autoComplete="off" />
                    <div className="login__register__form-register-inputs_error">{errors.password?.message}</div>
                  </div>
                  <div
                    className={`login__register__form-register-inputs_repeat ${
                      errors.confirmPassword?.message ? 'has-error' : ''
                    }`}
                  >
                    <input
                      type="password"
                      placeholder="Repeat password"
                      {...register('confirmPassword')}
                      formNoValidate
                      autoComplete="off"
                    />
                    <div className="login__register__form-register-inputs_error">{errors.confirmPassword?.message}</div>
                  </div>
                </div>
                <OutlinedButton
                  type="submit"
                  text="REGISTER"
                  borderColor={COLORS.PINK}
                  size="full"
                  fontSize="1.4rem"
                  color={COLORS.PINK}
                  outline
                />
              </form>
              <h4 className="login__body-form__wrapper-title-bold">Already registered?</h4>
              <OutlinedButton
                text="SIGN IN"
                borderColor={COLORS.PINK}
                size="full"
                fontSize="1.4rem"
                color={COLORS.WHITE}
                gradient
                onClick={() => setMode(LOGIN_MODE)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="login__footer">
        <Row className="login__footer-socials">
          <Col>
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </Col>
          <Col>
            <FontAwesomeIcon icon={['fab', 'whatsapp']} />
          </Col>
          <Col>
            <FontAwesomeIcon icon={['fab', 'facebook-f']} />
          </Col>
        </Row>
        <Row className="login__footer-copyright">
          <p>© Copyright, 2021</p>
        </Row>
      </div>
    </div>
  );
}
