import React, { useState } from 'react';

import './styles.scss';
import { COLORS } from '../../../constants/colors';
import CustomButton from '../../buttons/base';

export default function CustomerDetails({ setCustomerDetails }) {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [country] = useState('United States');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const states = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  const setData = () => {
    // TODO: Validate data
    const data = {
      firstname,
      lastname,
      email,
      phoneNumber,
      streetAddress,
      streetAddress2,
      country,
      state,
      zipCode
    };

    setCustomerDetails(data);
  };

  return (
    <>
      <div className="customer-details">
        <p className="customer-details__title">Customer details</p>
        <div className="customer-details__inputs">
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-firstname">First name</label>
            <input
              type="text"
              id="checkout-customer-details-firstname"
              value={firstname}
              onChange={event => setFirstname(event.target.value)}
            />
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-lastname">Last name</label>
            <input
              type="text"
              id="checkout-customer-details-lastname"
              value={lastname}
              onChange={event => setLastname(event.target.value)}
            />
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-email">Email</label>
            <input
              type="email"
              id="checkout-customer-details-email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-phone">Phone number</label>
            <input
              type="text"
              id="checkout-customer-details-phone"
              value={phoneNumber}
              onChange={event => setPhoneNumber(event.target.value)}
            />
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-address">Address</label>
            <input
              type="text"
              id="checkout-customer-details-address"
              value={streetAddress}
              onChange={event => setStreetAddress(event.target.value)}
            />
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-address">Address 2</label>
            <input
              type="text"
              id="checkout-customer-details-address"
              value={streetAddress2}
              onChange={event => setStreetAddress2(event.target.value)}
            />
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-country">Country or region</label>
            <div className="select select-disabled">
              <select disabled>
                <option selected value="United States">
                  United states
                </option>
              </select>
            </div>
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-state">State</label>
            <div className="select">
              <select onChange={event => setState(event.target.value)} value={state}>
                <option value="">---</option>
                {states.map(USState => (
                  <option key={`customer-state-${USState}`} value={USState}>
                    {USState}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="customer-details__inputs__input">
            <label htmlFor="checkout-customer-details-zip">ZIP</label>
            <input
              type="text"
              id="checkout-customer-details-zip"
              value={zipCode}
              onChange={event => setZipCode(event.target.value)}
            />
          </div>
        </div>
      </div>
      <CustomButton
        outline
        fontSize="1.4rem"
        size="full"
        text="GO ON"
        onClick={setData}
        borderColor={COLORS.PINK}
        color={COLORS.PINK}
      />
    </>
  );
}
