import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import './styles.scss';
import HearthSmall from 'assets/images/hearth-small.svg';
import HearthSmallFilled from 'assets/images/hearth-small-filled.svg';
import CartSmall from 'assets/images/cart-small.svg';
import { addItemToCart } from 'store/slices/cart/cartSlice';
import {updateWishlist} from "store/slices/user/userAPI";
import {checkWishlist, selectWishlist} from "store/slices/user/userSlice";

export default function Product({ product }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentWishlist = useSelector(selectWishlist);
  let isInWishlist = currentWishlist.some(wishlistProduct => wishlistProduct.id === product.id);

  useEffect(() => {
    isInWishlist = currentWishlist.some(wishlistProduct => wishlistProduct.id === product.id);
  }, [currentWishlist]);


  const addToCart = (event, localProduct) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(addItemToCart({ product: localProduct }));
  };

  const wishlist = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const wishlistedProduct = (await updateWishlist(id)).data;
      dispatch(checkWishlist(wishlistedProduct));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  return (
    <div className="product" id={product.id} onClick={() => history.push(`/product/${product.id}`)}>
      <div className="product__image">
        <img
          className="product__image-main"
          src={`${process.env.REACT_APP_API_URL}${product.mainImage.url}`}
          alt={product.name}
        />
        <div className="product__image-hearth" onClick={event => wishlist(event, product.id)}>
          {isInWishlist ?
            <img src={HearthSmallFilled} alt="remove from wishlist"/> :
            <img src={HearthSmall} alt="add to wishlist"/>
          }
        </div>
        <div className="product__image-cart" onClick={event => addToCart(event, product)}>
          <img src={CartSmall} alt="add to cart" />
        </div>
      </div>
      <div className="product__info">
        <p className="product__info-name">
          {product.categories?.length > 0 ? `${product.categories[0].name}/` : ''}
          {product.name}
        </p>
        <p className="product__info-price">
          Wholesale price <span>${product.price.toFixed(2)}</span>
        </p>
      </div>
    </div>
  );
}
