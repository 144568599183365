import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';

import './styles.scss';

import { selectNotificationsSettings } from 'store/slices/app/appSlice';

export default function TopHeaderNotification() {
  const notificationSettings = useSelector(selectNotificationsSettings);
  const shouldShowNotifications = () =>
    notificationSettings?.topNotification?.enabled && notificationSettings?.topNotification?.text;
  const [notificationStatus, setNotificationStatus] = useState(false);
  useEffect(() => {
    if (
      notificationSettings?.topNotification?.enabled &&
      notificationSettings?.topNotification?.text &&
      notificationSettings?.topNotification?.duration
    ) {
      setNotificationStatus(true);
      setTimeout(() => {
        setNotificationStatus(false);
      }, notificationSettings.topNotification.duration * 1000);
    }
  }, [notificationSettings]);
  return (
    <>
      {shouldShowNotifications() && (
        <div className={`top-header-notification ${notificationStatus ? 'show-notification' : 'hide-notification'}`}>
          <Row className="top-header-notification__row">
            <p className="top-header-notification__row-message">
              <ReactQuill value={notificationSettings.topNotification.text} modules={{ toolbar: false }} theme={null} readOnly />
            </p>
          </Row>
        </div>
      )}
    </>
  );
}
