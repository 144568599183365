import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import ReactQuill from 'react-quill';

import LeftArrow from 'assets/images/black-long-left-arrow.svg';
import RightArrow from 'assets/images/black-long-right-arrow.svg';
import CartImage from 'assets/images/cart-small.svg';
import CustomButton from 'components/buttons/base';
import ProductsCarousel from 'components/productsCarousel';
import { getProduct, getProductsFromTag } from 'store/slices/app/productAPI';
import { getCategory } from 'store/slices/app/categoryAPI';
import { addItemToCart } from 'store/slices/cart/cartSlice';

import { useMediaQuery } from '../../utils/hooks';

export default function Product() {
  const { id } = useParams();
  const slider = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState(null);
  const [similarItems, setSimilarItems] = useState(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const fetchData = async () => {
      const productResponse = await getProduct(id);
      const categoryResponse = await getCategory(productResponse?.data?.categories[0]?.id);
      // TODO: Replace with real similar items
      const similarItemsResponse = await getProductsFromTag(1, 1, 100);

      setProduct(productResponse?.data);
      setCategory(categoryResponse?.data);
      setSimilarItems(similarItemsResponse?.data);
    };
    fetchData();
  }, [id]);

  const addToCart = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(addItemToCart({ product, quantity }));
  };

  const next = () => {
    slider.current.slickNext();
  };

  const previous = () => {
    slider.current.slickPrev();
  };

  const goToCategory = categoryId => {
    if (categoryId) {
      history.push(`/category/${categoryId}`);
    }
  };

  const increaseByQuantity = qty => {
    if (qty >= 1 && qty <= 999) {
      setQuantity(qty);
    } else if (qty > 999) {
      setQuantity(999);
    } else {
      setQuantity(1);
    }
  };

  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    // beforeChange: (current, next) => {
    //     setNextDisabled(next >= nextAmount);
    //     setPrevDisabled(next === 0);
    //     setCurrentSlide(next);
    // },
  };

  return (
    <>
      {product && (
        <Container className="product" id={`product-view-${id}`}>
          <Row className="product__path">
            <p>
              {category?.pathCategories?.map(pathCategory => (
                <span key={`path-${pathCategory.id}`} onClick={() => goToCategory(pathCategory?.id)}>
                  {pathCategory.name} /{' '}
                </span>
              ))}
              <span onClick={() => goToCategory(category?.id)}>{category?.name} / </span>
              <span>{product?.name}</span>
            </p>
          </Row>
          <Row className="product__details">
            <Col xs={12} sm={12} md={6} className="product__details__left">
              <Slider {...settings} className="product__details__left-slider" ref={slider}>
                <div className="product__details__left-slider-image" key={product.mainImage.name}>
                  <img src={`${process.env.REACT_APP_API_URL}${product.mainImage.url}`} alt={product.mainImage.name} />
                </div>
                {product?.images?.map(image => (
                  <div className="product__details__left-slider-image" key={image.name}>
                    <img src={`${process.env.REACT_APP_API_URL}${image.url}`} alt={image.name} />
                  </div>
                ))}
              </Slider>
              {!isMobile && (
                <div className="product__details__left-controls">
                  <div className="product__details__left-controls-previous" onClick={previous}>
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img src={LeftArrow} alt="previous image" />
                    <p>Previous image</p>
                  </div>
                  <div className="product__details__left-controls-next" onClick={next}>
                    <p>Next image</p>
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img src={RightArrow} alt="previous image" />
                  </div>
                </div>
              )}
            </Col>
            <Col xs={6} className="product__details__right">
              <div className="product__details__right-title">
                <h3>{product.name}</h3>
              </div>
              <div className="product__details__right-item">
                <p>item#</p>
              </div>
              <div className="product__details__right-price">
                <p>
                  Wholesale price <span>${product.price.toFixed(2)}</span>
                </p>
              </div>
              <Row className="product__details__right__hr">
                <Col className="product__details__right__hr-line" />
              </Row>
              <div className="product__details__right__qty">
                <button type="button" onClick={() => decreaseQuantity()}>
                  -
                </button>
                <input
                  type="number"
                  min="1"
                  max="999"
                  value={quantity}
                  onChange={event => increaseByQuantity(parseInt(event.target.value, 10))}
                />
                <button type="button" onClick={() => increaseQuantity()}>
                  +
                </button>
              </div>
              <div className="product__details__right__actions">
                <CustomButton
                  gradient
                  size="medium"
                  text="BUY"
                  icon="long-arrow-right"
                  height="52px"
                  color="white"
                  fontSize="1.4rem"
                />
                <button className="product__details__right__actions-cart" type="button" onClick={event => addToCart(event)}>
                  <img src={CartImage} alt="add to cart" />
                </button>
              </div>
              <div className="product__details__right__description">
                <p className="product__details__right__description-title">Item description</p>
                <p className="product__details__right__description-text">
                  <ReactQuill value={product.description} modules={{ toolbar: false }} theme={null} readOnly />
                </p>
              </div>
            </Col>
          </Row>
          {similarItems && (
            <Row className="product__similar">
              {/* TODO: Choose products to be shown */}
              <ProductsCarousel products={similarItems || []} title="Similar items" />
            </Row>
          )}
        </Container>
      )}
    </>
  );
}
