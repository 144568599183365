import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function getAllCategories() {
  return axios.get(`${BASE_URL}/product-categories`);
}

export async function getCategory(id) {
  return axios.get(`${BASE_URL}/product-categories/${id}`);
}
