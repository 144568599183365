import React from 'react';
import './styles.scss';

import RightLongArrow from 'assets/images/white-long-right-arrow.svg';

export default function Button({
  text,
  borderColor,
  size,
  fontSize,
  color,
  gradient,
  outline,
  icon,
  height,
  width,
  maxWidth,
  borderRadius,
  backgroundColor,
  border,
  ...rest
}) {
  return (
    <button
      className={`base-button ${size} ${outline ? 'outline' : ''} ${gradient ? 'gradient' : ''}`}
      style={{ borderColor, border, fontSize, color, height, width, maxWidth, borderRadius, backgroundColor }}
      type="button"
      {...rest}
    >
      {text}
      {icon && icon === 'long-arrow-right' && <img src={RightLongArrow} alt="right arrow" />}
    </button>
  );
}
