import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export async function login(identifier, password) {
  return axios.post(`${BASE_URL}/auth/local`, { identifier, password });
}

export async function authenticate() {
  return axios.get(`${BASE_URL}/users/me`);
}

export async function wishlist() {
  return axios.get(`${BASE_URL}/products/wishlist`);
}

export async function updateWishlist(id) {
  return axios.put(`${BASE_URL}/products/wishlist/${id}`);
}

export async function orders() {
  return axios.get(`${BASE_URL}/orders`);
}

export async function register({ username, email, password, firstname, lastname, phoneNumber }) {
  return axios.post(`${BASE_URL}/auth/local/register`, {
    username,
    email,
    password,
    firstname,
    lastname,
    phoneNumber
  });
}
