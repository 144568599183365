import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import history from 'store/history';

import { login, authenticate, register, orders, wishlist } from './userAPI';

const initialState = {
  value: 0,
  profile: null,
  orders: [],
  wishlist: [],
  status: 'idle',
  error: null
};

export const userLogin = createAsyncThunk('user/login', async ({ email, password }) => {
  let response = null;
  try {
    response = (await login(email, password)).data;
  } catch (error) {
    const customError = {
      name: error.response.statusText,
      message: error.response.data?.data[0]?.messages[0]?.message // serializable
    };
    throw customError;
  }

  return response;
});

export const userRegister = createAsyncThunk('user/register', async data => {
  let response = null;
  try {
    response = await register(data);
  } catch (error) {
    const customError = {
      name: error.response.statusText,
      message: error.response.data?.data[0]?.messages[0]?.message // serializable
    };
    throw customError;
  }

  return response.data;
});

export const userWishlist = createAsyncThunk('user/wishlist', async () => {
  const response = await wishlist();

  return response.data;
});

export const userAuthenticate = createAsyncThunk('user/authenticate', async () => {
  const response = await authenticate();

  return response.data;
});

export const userOrders = createAsyncThunk('user/myOrders', async () => {
  const response = await orders();

  return response.data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut: state => {
      state.profile = null;
      localStorage.removeItem('be_token');
    },
    clearError: state => {
      state.error = null;
    },
    checkWishlist: (state, action) => {
      const product = action.payload;
      if (product?.id) {
        const exists = state.wishlist.some(wishlistProduct => wishlistProduct.id === product.id);
        if (exists) {
          state.wishlist = state.wishlist.filter(wishlistProduct => wishlistProduct.id !== product.id);
        } else {
          state.wishlist = [...state.wishlist, product];
        }
      }
    }
  },

  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        state.error = null;
        state.status = 'loading';
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.error = null;
        state.status = 'idle';
        localStorage.setItem('be_token', action.payload.jwt);
        state.profile = action.payload.user;
        setTimeout(() => history.push('/'));
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error?.message;
      })
      .addCase(userAuthenticate.pending, state => {
        state.status = 'loading';
      })
      .addCase(userAuthenticate.fulfilled, (state, action) => {
        state.status = 'idle';
        state.profile = action.payload;
      })
      .addCase(userAuthenticate.rejected, state => {
        state.status = 'idle';
        localStorage.removeItem('be_token');
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
        localStorage.setItem('be_token', action.payload.jwt);
        state.profile = action.payload.user;
        setTimeout(() => history.push('/'));
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.error?.message;
        localStorage.removeItem('be_token');
      })
      .addCase(userRegister.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(userOrders.pending, state => {
        state.status = 'loading';
      })
      .addCase(userOrders.fulfilled, (state, action) => {
        state.status = 'idle';
        state.orders = action.payload;
      })
      .addCase(userWishlist.pending, state => {
        state.status = 'loading';
      })
      .addCase(userWishlist.fulfilled, (state, action) => {
        state.status = 'idle';
        state.wishlist = action.payload;
      });
  }
});

export const { logOut, clearError, checkWishlist } = userSlice.actions;

export const selectUser = state => state.user.profile;
export const selectMyOrders = state => state.user.orders;
export const selectIsAuthenticated = state => !!state.user.profile || localStorage.getItem('be_token');
export const selectError = state => state.user.error || '';
export const selectWishlist = state => state.user.wishlist || [];

export default userSlice.reducer;
