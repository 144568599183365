import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faInstagram, faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  faSearch,
  faShoppingCart,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faTimes,
  faUser,
  faChevronDown,
  faBars
} from '@fortawesome/free-solid-svg-icons';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

import { store } from 'store/store';
import App from 'App';

library.add(
  fab,
  faSearch,
  faShoppingCart,
  faInstagram,
  faFacebookF,
  faWhatsapp,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faTimes,
  faUser,
  faChevronDown,
  faBars
);

axios.interceptors.request.use(
  config => {
    const backedToken = localStorage.getItem('be_token');
    if (backedToken && !config.url.includes('auth/local')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${backedToken}`;
    }
    return config;
  },
  // eslint-disable-next-line promise/no-promise-in-callback
  error => Promise.reject(error)
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
