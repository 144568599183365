import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// eslint-disable-next-line no-unused-vars
history.listen((location, action) => {
  window.scrollTo(0, 0);
});

export default history;
