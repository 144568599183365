import React from 'react';
import './styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Logo from 'assets/images/logo.png';
import HeaderMenu from 'components/menus/headerMenu';
import { selectIsAuthenticated } from 'store/slices/user/userSlice';
import { toggleCart } from 'store/slices/cart/cartSlice';
import { toggleOverlay, toggleSideMenu } from 'store/slices/app/appSlice';
import SearchIcon from 'assets/images/search.svg';
import CartIcon from 'assets/images/cart-small.svg';
import { useMediaQuery } from 'utils/hooks';
import { COLORS } from 'constants/colors';

export default function Header() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const toggleCartAndOverlay = () => {
    dispatch(toggleOverlay());
    dispatch(toggleCart());
  };

  const toggleSideMenuAndOverlay = () => {
    dispatch(toggleOverlay());
    dispatch(toggleSideMenu());
  };

  return (
    <Container className="header">
      <Row className="header__row">
        <Col xs={4} className="header__row__button-col">
          {/* <CustomButton
            outline
            fontSize="1.4rem"
            size="small"
            text={isAuthenticated ? 'My Account' : 'Sign in/Register'}
            onClick={() => (isAuthenticated ? history.push('/profile') : history.push('/login'))}
            borderColor={COLORS.PINK}
          /> */}
          {!isMobile && (
            <FontAwesomeIcon icon="user" onClick={() => (isAuthenticated ? history.push('/profile') : history.push('/login'))} />
          )}
          {isMobile && (
            <FontAwesomeIcon
              icon="bars"
              color={COLORS.PINK}
              onClick={() => (isAuthenticated ? toggleSideMenuAndOverlay() : history.push('/login'))}
            />
          )}
        </Col>
        <Col xs={4} className="header__row__logo-col">
          <img src={Logo} alt="sl logo" onClick={() => history.push('/')} />
        </Col>
        <Col xs={4} className="header__row__icons-col">
          <img src={SearchIcon} alt="search" />
          <img src={CartIcon} alt="search" onClick={toggleCartAndOverlay} />
        </Col>
      </Row>
      {!isMobile && <HeaderMenu />}
    </Container>
  );
}
