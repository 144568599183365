import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import './styles.scss';
import { selectMenuSettings, toggleOverlay, toggleSideMenu } from 'store/slices/app/appSlice';
import { useMediaQuery } from 'utils/hooks';

export default function HeaderMenu() {
  const dispatch = useDispatch();
  const history = useHistory();
  // TODO: Replace from store, active page link or match with current url.
  const [active, setActive] = useState(history.location.pathname);
  const menuSettings = useSelector(selectMenuSettings);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const shouldShowMenu = () => menuSettings?.headerMenu?.menuLinks && menuSettings.headerMenu.menuLinks.length > 0;
  const getMenuItemName = menuLink => menuLink?.Label || menuLink?.tag?.name || menuLink?.category?.name || '';
  const getMenuItemId = menuLink => menuLink?.tag?.id || menuLink?.category?.id || menuLink?.id;
  const getMenuItemType = menuLink => {
    if (menuLink?.tag?.id) return 'tag';
    if (menuLink?.category?.id) return 'category';
    return 'link';
  };

  history.listen(location => {
    setActive(location.pathname);
  });

  const toggleSideMenuAndOverlay = () => {
    dispatch(toggleOverlay());
    dispatch(toggleSideMenu());
  };

  const goOnMenuItem = menuItem => {
    const menuItemType = getMenuItemType(menuItem);
    if (isMobile) {
      toggleSideMenuAndOverlay();
    }
    switch (menuItemType) {
      case 'tag':
        history.push(`/tag/${menuItem.tag.id}`);
        break;
      case 'category':
        history.push(`/category/${menuItem.category.id}`);
        break;
      default: {
        if (menuItem.customLink && menuItem.customLink.startsWith('/')) {
          history.push(menuItem.customLink);
        } else {
          window.location = menuItem.customLink;
        }
        break;
      }
    }
  };

  const getMenuId = menuItem => {
    const menuItemType = getMenuItemType(menuItem);
    switch (menuItemType) {
      case 'tag':
        return `/tag/${menuItem.tag.id}`;
      case 'category':
        return `/category/${menuItem.category.id}`;
      default: {
        if (menuItem.customLink && menuItem.customLink.startsWith('/')) {
          return menuItem.customLink;
        }
        return menuItem.customLink;
      }
    }
  };

  return (
    <>
      {shouldShowMenu() && (
        <>
          <Row className="header-row">
            {menuSettings.headerMenu.menuLinks
              .filter(item => !item.submenuLevel)
              .map(item => (
                <Col
                  xs={isMobile ? 12 : null}
                  className="header-row__menu-item"
                  key={`${getMenuItemType(item)}-${getMenuItemId(item)}`}
                >
                  <p
                    className={`header-row__menu-item-text ${getMenuId(item) === active ? 'active' : ''} `}
                    onClick={() => goOnMenuItem(item)}
                  >
                    {getMenuItemName(item)}
                  </p>
                </Col>
              ))}
          </Row>
          {isMobile && (
            <>
              <hr className="cart__hr-title" />
              <Row className="header-row">
                <Col xs={isMobile ? 12 : null} className="header-row__menu-item">
                  <p
                    className={`header-row__menu-item-text ${active === '/profile' ? 'active' : ''} `}
                    onClick={() => goOnMenuItem({ customLink: '/profile' })}
                  >
                    My Account
                  </p>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
}
