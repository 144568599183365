import React from 'react';
import './styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { selectGeneralSettings, selectMenuSettings } from '../../store/slices/app/appSlice';
import { useMediaQuery } from '../../utils/hooks';

export default function Footer() {
  const menuSettings = useSelector(selectMenuSettings);
  const generalSettings = useSelector(selectGeneralSettings);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const getFooterMenuLinkName = item => item?.category?.name || item?.tag?.name || item.Label || '';

  // TODO: Add logic for link navigation
  return (
    <div className="footer">
      <Container>
        {!isMobile && (
          <Row className="footer__menu">
            {menuSettings?.footerMenu?.menuLinks?.map(item => (
              <Col key={`footer-item-${item.id}`}>
                <p className={`footer__menu-item ${item.id === 1 ? 'active-item' : ''}`}>{getFooterMenuLinkName(item)}</p>
              </Col>
            ))}
          </Row>
        )}
        <Row className="footer__hr">
          <Col className="footer__hr-line" />
        </Row>
        {generalSettings?.socialLinks && (
          <Row className="footer__socials">
            <div className="footer__socials-inner">
              {generalSettings.socialLinks.map(socialLink => (
                <div key={socialLink.social}>
                  <FontAwesomeIcon
                    icon={[
                      'fab',
                      socialLink.social.toLowerCase() === 'facebook' ? 'facebook-f' : socialLink.social.toLowerCase()
                    ]}
                  />
                </div>
              ))}
            </div>
          </Row>
        )}
        {generalSettings?.copyrightText && (
          <Row className="footer__copyright">
            <p>{generalSettings?.copyrightText}</p>
          </Row>
        )}
      </Container>
    </div>
  );
}
