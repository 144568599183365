import React, { useState } from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';

import './styles.scss';
import { COLORS } from 'constants/colors';
import CustomButton from 'components/buttons/base';
import Checkmark from 'assets/images/checkmark.svg';

export default function Appointment() {
  const history = useHistory();
  const helps = ['Help 1', 'Help 2', 'Help 3', 'Help 4', 'Help 5', 'Help 6'];
  const [help, setHelp] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [visitDate, setVisitDate] = useState('');
  const [socialMedia, setSocialMedia] = useState('');
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const orderStatus = 'idle';

  const handleClose = () => {
    history.push('/');
  };

  const handleShow = () => setShow(true);

  const toggleThanksModal = () => {
    handleShow();
  };

  return (
    <Container className="appointment-container">
      <Row>
        <div className="appointment">
          <div className="appointment__header">
            <p>Get an appointment</p>
          </div>
          <div className="appointment__form-wrapper">
            <div className="appointment__form">
              <div className="input-wrapper">
                <label htmlFor="profile-inputs-state">How can we help?</label>
                <div className="select">
                  <select onChange={event => setHelp(event.target.value)} value={help}>
                    <option value="">---</option>
                    {/* TODO: Fix pre-selected */}
                    {helps.map(predefinedHelp => (
                      <option key={`profile-state-${predefinedHelp}`} value={predefinedHelp} selected={help === predefinedHelp}>
                        {predefinedHelp}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input-wrapper">
                <label htmlFor="appointment-inputs-name">Your name?*</label>
                <input
                  type="text"
                  placeholder=""
                  id="appointment-inputs-name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="appointment-inputs-phone">Your phone number?*</label>
                <input
                  type="text"
                  placeholder=""
                  id="appointment-inputs-phone"
                  value={phoneNumber}
                  onChange={event => setPhoneNumber(event.target.value)}
                />
              </div>
            </div>
            <div className="appointment__form">
              <div className="input-wrapper">
                <label htmlFor="appointment-inputs-date">Visit Date?*</label>
                <input
                  type="text"
                  placeholder=""
                  id="appointment-inputs-date"
                  value={visitDate}
                  onChange={event => setVisitDate(event.target.value)}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="appointment-inputs-social">Social media?*</label>
                <input
                  type="text"
                  placeholder=""
                  id="appointment-inputs-social"
                  value={socialMedia}
                  onChange={event => setSocialMedia(event.target.value)}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="appointment-inputs-email">Your Email?*</label>
                <input
                  type="email"
                  placeholder=""
                  id="appointment-inputs-email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="appointment__action">
            <CustomButton
              outline
              fontSize="1.4rem"
              size="full"
              text="BOOK NOW"
              width="25%"
              onClick={toggleThanksModal}
              borderColor={COLORS.PINK}
              color={COLORS.PINK}
            />
          </div>
        </div>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
        className={`appointment-dialog ${orderStatus === 'loading' ? 'loading-dialog' : ''}`}
      >
        <Modal.Header closeButton>
          {orderStatus === 'idle' && <img src={Checkmark} alt="" />}
          {orderStatus === 'loading' && (
            <div className="lds-spinner">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
          {orderStatus === 'idle' && <p>Thank you for your booking!</p>}
          {orderStatus === 'loading' && <p className="loading-message">Please wait</p>}
          {orderStatus === 'loading' && <p className="loading-message">We are processing your request</p>}
        </Modal.Header>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        {orderStatus === 'idle' && (
          <Modal.Body>
            <div className="appointment-dialog-shipping-info">
              <div className="shipping-title">
                <p>I would like...</p>
              </div>
              <div className="shipping-info">
                <div className="column">
                  <p>
                    Name: <span>{name}</span>
                  </p>
                  <p>
                    Phone number: <span>{phoneNumber}</span>
                  </p>
                  <p>
                    Visit date: <span>{visitDate}</span>
                  </p>
                </div>
                <div className="column">
                  <p>
                    Social media: <span>{socialMedia}</span>
                  </p>
                  <p>
                    Your email: <span>{email}</span>
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </Container>
  );
}
