import React, { useState } from 'react';
import { Col, Row, Container, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import './styles.scss';

import {
  clearCart,
  postOrder,
  selectCartItems,
  selectCartSubtotla,
  selectCartTotal,
  selectLastConfirmedOrder,
  selectOrderError,
  selectOrderStatus,
  setItemQuantity
} from 'store/slices/cart/cartSlice';
import PaymentProviders from 'assets/images/card-payment-providers.png';
import Checkmark from 'assets/images/checkmark.svg';
import TimesCircle from 'assets/images/times-circle.svg';
import CustomerDetails from 'components/checkout/customerDetails';
import Shipping from 'components/checkout/shipping';
import Payment from 'components/checkout/payment';

import { useMediaQuery } from '../../utils/hooks';

// eslint-disable-next-line complexity
export default function Checkout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const orderStatus = useSelector(selectOrderStatus);
  // eslint-disable-next-line no-unused-vars
  const orderError = useSelector(selectOrderError);
  const [error, setError] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [shippingDetails, setShippingDetails] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [paymentDetails, setPaymentDetails] = useState({});
  const [show, setShow] = useState(false);
  const lastConfirmedOrder = useSelector(selectLastConfirmedOrder);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const cartItems = useSelector(selectCartItems);
  const cartSubtotal = useSelector(selectCartSubtotla);
  const cartTotal = useSelector(selectCartTotal);
  const [currentStep, setStep] = useState(1);
  const MAX_STEPS = 3;

  const handleClose = () => {
    dispatch(clearCart());
    history.push('/');
  };
  const handleShow = () => setShow(true);

  const createOrder = () => {
    const orderData = {
      orderedProducts: cartItems.map(cartItem => ({ product: { id: cartItem.id }, quantity: cartItem.quantity })),
      shippingDetails: customerDetails,
      billingDetails: customerDetails,
      total: cartTotal
    };

    if (cartItems.length === 0) {
      setError(true);
      setTimeout(() => {
        history.push('/');
      }, 3000);
    } else {
      dispatch(postOrder(orderData));
    }
    handleShow();
  };

  const handleStep = () => {
    if (currentStep > 0 && currentStep < MAX_STEPS) {
      setStep(currentStep + 1);
    } else if (currentStep === MAX_STEPS) {
      createOrder();
    }
  };

  const setCustomerDetailsData = data => {
    setCustomerDetails(data);
    handleStep();
  };

  const setShippingDetailsData = data => {
    setShippingDetails(data);
    handleStep();
  };

  const setPaymentDetailsData = data => {
    setPaymentDetails(data);
    handleStep();
  };

  const changeQuantity = (quantity, id) => {
    dispatch(setItemQuantity({ id, quantity }));
  };

  return (
    <Container className="checkout">
      <Row className="checkout__inner-wrapper">
        <Col className="checkout__customer-details" xs={12} sm={12} md={6}>
          <div className="checkout__customer-details__wrapper">
            {currentStep === 1 && <CustomerDetails setCustomerDetails={setCustomerDetailsData} />}
            {currentStep === 2 && <Shipping setShippingDetails={setShippingDetailsData} />}
            {currentStep === 3 && <Payment setPaymentDetails={setPaymentDetailsData} />}
          </div>
        </Col>
        <Col className="checkout__order-preview" xs={12} sm={12} md={6}>
          <div className="checkout__order-preview__wrapper">
            <p className="checkout__order-preview__title">Total for payment</p>
            <div className="checkout__order-preview__products">
              {cartItems.map(cartItem => (
                <div className="checkout__order-preview__product" key={`checkout-cart-product-${cartItem?.id}`}>
                  <div className="checkout__order-preview__product__title">
                    <div className="checkout__order-preview__product__title-info">
                      <p className="title">{cartItem?.name}</p>
                      <p className="subtitle">SKU: {cartItem?.SKU}</p>
                    </div>
                    <div className="checkout__order-preview__product__title-price">
                      <p className="price">${cartItem?.price || '0.00'}</p>
                    </div>
                  </div>
                  <div className="checkout__order-preview__product__details">
                    <Row className="details">
                      <Col xs={4} sm={4} md={4} lg={4} xl={2} className="details__image">
                        <img src={`${process.env.REACT_APP_API_URL}${cartItem?.mainImage?.url}`} alt="ring" />
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={8} xl={10} className="details__quantity">
                        <div className="details__quantity-selectors">
                          <button type="button" onClick={() => changeQuantity(cartItem?.quantity - 1 || 1, cartItem?.id)}>
                            -
                          </button>
                          <input disabled type="number" value={cartItem?.quantity || 1} />
                          <button type="button" onClick={() => changeQuantity(cartItem?.quantity + 1, cartItem?.id)}>
                            +
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
              {(!cartItems || cartItems.length === 0) && <p className="checkout__order-preview__no-products">Cart is empty</p>}
            </div>
            <div className="checkout__order-preview__totals">
              <div className="total-items">
                <p>Items</p>
                <p>${cartSubtotal?.toFixed(2) || '0.00'}</p>
              </div>
              <div className="total-delivery">
                <p>Delivery</p>
                <p>$0.00</p>
              </div>
              <div className="total">
                <p>TOTAL</p>
                <p>${cartTotal?.toFixed(2) || '0.00'}</p>
              </div>
            </div>
            <div className="checkout__order-preview__payment-providers">
              <img src={PaymentProviders} alt="payment-providers" />
            </div>
          </div>
        </Col>
      </Row>
      {/* TODO: Clean up this mess! */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
        className={`order-dialog ${orderStatus === 'loading' ? 'loading-dialog' : ''}`}
      >
        <Modal.Header closeButton>
          {orderStatus === 'idle' && !error && <img src={Checkmark} alt="" />}
          {error && <img src={TimesCircle} alt="" />}
          {orderStatus === 'loading' && !error && (
            <div className="lds-spinner">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          )}
          {orderStatus === 'idle' && <p>{error ? 'Error' : 'Thank you for your order!'}</p>}
          {orderStatus === 'loading' && !error && <p className="loading-message">Please wait</p>}
          {orderStatus === 'loading' && !error && <p className="loading-message">We are processing your order</p>}
        </Modal.Header>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        {orderStatus === 'idle' && !error && (
          <Modal.Body>
            <div className="order-dialog-shipping-info">
              <div className="shipping-title">
                <p>Shipping</p>
              </div>
              <div className="shipping-info">
                <div className="column">
                  <p>
                    First name: <span>{lastConfirmedOrder?.shippingDetails?.firstname}</span>
                  </p>
                  <p>
                    Last name: <span>{lastConfirmedOrder?.shippingDetails?.lastname}</span>
                  </p>
                  <p>
                    Email: <span>{lastConfirmedOrder?.shippingDetails?.email}</span>
                  </p>
                </div>
                <div className="column">
                  <p>
                    Phone: <span>{lastConfirmedOrder?.shippingDetails?.phoneNumber}</span>
                  </p>
                  <p>
                    Address: <span>{lastConfirmedOrder?.shippingDetails?.streetAddress}</span>
                  </p>
                  <p>
                    Address 2: <span>{lastConfirmedOrder?.shippingDetails?.streetAddress2}</span>
                  </p>
                </div>
                <div className="column">
                  <p>
                    Country: <span>United States</span>
                  </p>
                  <p>
                    State: <span>{lastConfirmedOrder?.shippingDetails?.state}</span>
                  </p>
                  <p>
                    Zip Code: <span>{lastConfirmedOrder?.shippingDetails?.zipCode}</span>
                  </p>
                </div>
              </div>
              <div className="order-summary">
                <p>
                  Order number: <span>{lastConfirmedOrder?.orderId}</span>
                </p>
                <p>
                  Shipping: <span>Free Shipping</span>
                </p>
                <p>
                  Payment method: <span>Credit card</span>
                </p>
              </div>
              <div className="ordered-products">
                <div className="table-row table-header">
                  <p className="product-col">Product</p>
                  <p className="price-col">Price</p>
                  <p className="quantity-col">Quantity</p>
                  <p className="total-col">Total</p>
                </div>
                {lastConfirmedOrder?.orderedProducts?.map(orderedProduct => (
                  <div key={`productId-${orderedProduct.id}`} className="table-row">
                    <p className="product-col">{orderedProduct.product?.name}</p>
                    <p className="price-col">${orderedProduct.product?.price?.toFixed(2) || '0.00'}</p>
                    <p className="quantity-col">{orderedProduct.quantity}</p>
                    <p className="total-col">
                      ${((orderedProduct.product?.price || 0) * (orderedProduct.quantity || 1)).toFixed(2) || '0.00'}
                    </p>
                  </div>
                ))}
                <div className="table-row total-row mt-15">
                  <p className="product-col" />
                  <p className="price-col">{isMobile ? '' : 'Subtotal:'}</p>
                  <p className="quantity-col">{isMobile ? 'Subtotal:' : ''} </p>
                  <p className="total-col">${lastConfirmedOrder?.total || '0.00'}</p>
                </div>
                <div className="table-row total-row">
                  <p className="product-col" />
                  <p className="price-col">{isMobile ? '' : 'Shipping:'}</p>
                  <p className="quantity-col">{isMobile ? 'Shipping:' : ''}</p>
                  <p className="total-col">$0.00</p>
                </div>
                <div className="table-row total-row">
                  <p className="product-col" />
                  <p className="price-col grand-total">{isMobile ? '' : 'Total:'}</p>
                  <p className="quantity-col">{isMobile ? 'Total:' : ''}</p>
                  <p className="total-col grand-total">${lastConfirmedOrder?.total || '0.00'}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
        {error && (
          <Modal.Body>
            <div className="error-wrapper">
              <p className="error-message">Please wait a moment we will redirect you to the home page</p>
              <div className="lds-spinner">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </Container>
  );
}
