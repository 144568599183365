import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Product from 'components/product';
import { COLORS } from 'constants/colors';
import CustomButton from 'components/buttons/base';
import { getProductsFromCategories } from 'store/slices/app/productAPI';
import { getCategory } from 'store/slices/app/categoryAPI';

export default function Category() {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage] = useState(4);
  const productsPerPage = 50;

  const getSubcategoriesIds = (subcategories = []) => {
    let subcategoryIds = [];
    for (let i = 0; i < subcategories.length; i++) {
      const subcategory = subcategories[i];
      subcategoryIds.push(subcategory.id);
      subcategoryIds = [...subcategoryIds, ...getSubcategoriesIds(subcategory.subcategories || [])];
    }

    return subcategoryIds;
  };

  const fetchData = async (flush = false) => {
    let productsData = [];
    let categoriesProducts = [];
    const categoryResponse = await getCategory(id);
    const categoryData = categoryResponse?.data;
    const categoriesIds = [id, ...getSubcategoriesIds(categoryData?.subcategories || [])];
    if (categoriesIds && categoriesIds.length > 0) {
      const categoriesProductsResponse = await getProductsFromCategories(categoriesIds, page, productsPerPage);
      categoriesProducts = categoriesProductsResponse?.data || [];
    }
    if (flush) {
      productsData = categoriesProducts;
    } else {
      productsData = [...products, ...categoriesProducts];
    }
    setProducts(productsData || []);
    setCategory(categoryData);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    setProducts([]);
    setPage(1);
    setCategory(null);
    fetchData(true);
  }, [id]);

  return (
    <>
      <Container className="category">
        {category && (
          <Row className="category__title">
            <p>
              {category.name} / {category.productsCount} {category.productsCount > 1 ? 'Items' : 'Item'}
            </p>
          </Row>
        )}
        <Row className="category__products">
          {products.map(product => (
            <Col xs={6} sm={6} md={12 / perPage} key={`product-${product.id}`} className="category__products__product">
              <Product product={product} />
            </Col>
          ))}
        </Row>
        <Row className="category__load-more">
          <CustomButton
            disabled={!category?.productsCount || products?.length >= category?.productsCount}
            outline
            fontSize="1.4rem"
            size="small"
            text="LOAD MORE"
            onClick={() => setPage(page + 1)}
            borderColor={COLORS.PINK}
            height="5rem"
            color={COLORS.PINK}
          />
        </Row>
      </Container>
    </>
  );
}
