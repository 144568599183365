import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import './styles.scss';
import CloseImage from 'assets/images/close.svg';
import { selectSideMenuStatus, toggleOverlay, toggleSideMenu } from 'store/slices/app/appSlice';
import HeaderMenu from 'components/menus/headerMenu';
import Logo from 'assets/images/logo.png';

export default function SideMenu() {
  const dispatch = useDispatch();
  const history = useHistory();

  const sideMenuStatus = useSelector(selectSideMenuStatus);

  const toggleSideMenuAndOverlay = () => {
    dispatch(toggleOverlay());
    dispatch(toggleSideMenu());
  };

  const goHome = () => {
    toggleSideMenuAndOverlay();
    history.push('/');
  };

  return (
    <div className={`sideMenu ${sideMenuStatus ? '' : 'sideMenu_hidden'}`}>
      <Row className="sideMenu__header">
        <Col className="sideMenu__header-title">
          <img src={Logo} alt="sl logo" onClick={goHome} />
        </Col>
        <Col className="sideMenu__header-close">
          <img src={CloseImage} alt="close" onClick={toggleSideMenuAndOverlay} />{' '}
        </Col>
      </Row>
      <hr className="sideMenu__hr-title" />
      <div className="sideMenu-wrapper">
        <HeaderMenu />
      </div>
    </div>
  );
}
